import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useApiRequestMappers} from "@/shared/composables/use-api-request-mappers";

export const useTravelInformationApi = () => {
  const {logger, httpClient} = useApplicationContext();
  const {keysToSnakeCase, keysToCamel} = useApiRequestMappers();

  const getAirlineInformationMatchingOperator = async (operatorStartingWith, publicAccessToken) => {
    let url = `/travel-information/airlines?operator=${operatorStartingWith.trim() ? operatorStartingWith : ''}`;

    if (publicAccessToken) {
      url += `&token=${publicAccessToken}`
    }
    try {
      // logger.debug(`Fetching any airline information with name starting with (${operatorStartingWith})`);
      const response = await httpClient.get(url);
      const airlinesInformation = keysToCamel(response.data);

      // logger.debug(`Matched (${airlinesInformation.length}) airline using operator (${operatorStartingWith})`);
      return airlinesInformation;
    } catch (error) {
      logger.error('Failed to fetch any airline information', error);
      throw  error;
    }
  };

  const getAirportsMatchingIataCode = async (iataCode, publicAccessToken) => {
    let url = `/travel-information/airports?ic=${iataCode.trim() ? iataCode : ''}`;

    if (publicAccessToken) {
      url += `&token=${publicAccessToken}`
    }

    try {
      // logger.debug(`Searching airports with IATA code matching (${iataCode})`);
      const response = await httpClient.get(url);
      const airports = keysToCamel(response.data);

      // logger.debug(`Matched (${airports.length}) airports matching all/part of IATA code (${iataCode})`);
      return airports;
    } catch (error) {
      logger.error('Failed to search airport information', error);
      throw  error;
    }
  };

  return {
    getAirlineInformationMatchingOperator,
    getAirportsMatchingIataCode,
  };
};
