]
<template>
    <b-modal
      id="modal-select2"
      title="Information avancée"
      ok-title="Enregistrer"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="submitCreateNewTransportOption"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
            <b-col md="6">
              <b-form-group
                label="Nom de l'option"
                label-for="transport-option-name"
              >
                <b-form-input
                  v-model="option.name"
                  placeholder="Escapade à Paris"
                />
              </b-form-group>
            </b-col>
            <!--
            <b-col md="6">
              <b-form-group
                label="Type de transport"
                label-for="transport-option-type"
              >
              <v-select
                class="transport-type"
                :options="groupCreationConfig.transportType"
                v-model="option.transportType"
                label="text"
                :reduce="option => option.value"

              />
            </b-form-group>
            -->
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
              
            <b-form-group
              label="Inventaire"
              label-for="transport-options-inventory"
            >
              <b-form-input 
                v-model="option.inventory"
                placeholder="232"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date limite - Relache d'inventaire"
              label-for="option-inventory-release-limit-date"
            >
               <b-form-input
                id="option-inventory-release-limit-date"
                class="mb-1"
                type="date"
                max="2100-01-01"
                v-model="option.inventoryReleaseDate"
                name="option-inventory-release-limit-date"
                locale="fr"
                placeholder="Aucune date sélectionnée"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Date limite - Paiement final"
              label-for="transport-options-payment-limit-date"
            >
              <b-form-input
                id="transport-options-payment-limit-date"
                class="mb-1"
                type="date"
                max="2100-01-01"
                v-model="option.paymentLimitDate"
                name="transport-options-payment-limit-date"
                locale="fr"
                placeholder="Aucune date sélectionnée"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Tour Operateur"
              label-for="tour-operator"
            >
              <v-select
                id="tour-operator"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupCreationConfig.tourOperators"
                :selectable="option => ! option.value.includes('select_value')"
                :reduce="option => option.value"

                label="text"
                v-model="option.tourOperator"

              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Sélection de siège"
              label-for="transport-options"
            >
              <v-select
                id="group-type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupCreationConfig.seatsSelection"
                :selectable="option => ! option.value.includes('select_value')"
                :reduce="option => option.value"
                label="text"
                v-model="option.seatSelection"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup, 
  BFormCheckbox,
  BInputGroupPrepend,
  BFormTextarea,
  BTabs,
  BFormFile,
  BTab,
  BFormInvalidFeedback,
  
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import groupCreationConfig from '@core/data/groupCreationConfig.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup, 
    BFormCheckbox,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    editOption: Object,
  },
  data() {
    return {
      groupCreationConfig,
      option: {...this.editOption}
    }
  },
  methods: {
    submitCreateNewTransportOption (event) {
      this.$emit('editTransportOption', this.option)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
