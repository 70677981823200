<template>
  <b-row>
    <b-col cols="12">
      <div>
        <b-tabs v-model="tabIndex" class="mt-2" content-class="mt-2" justified>
          <group-creation-wizard-basic-information-tab :can-edit="canEdit" :group-id="groupId" @currentGroupId='updateGroupId' @next-tab="tabIndex++" :disabled=lockedSection />
          <group-creation-wizard-advanced-information-tab :can-edit="canEdit" :group-id="groupId" @next-tab="tabIndex++" :disabled=lockedSection />
          <b-tab :title="t('group.creation_steps.step_3')" :disabled=lockedSection>
            <group-create-transport-options :can-edit="canEdit" :group-id="groupId" @next-tab="tabIndex++" />
          </b-tab>
          <b-tab :title="t('group.creation_steps.step_4')" :disabled=lockedSection>
            <group-create-accommodation-options @complete-group="completeGroup" :group-id="groupId" />
          </b-tab>
        </b-tabs>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BTabs, BTab} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {provide, ref} from "@vue/composition-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import GroupCreateAdvancedInfo from "@/modules/group/creation-wizard/GroupCreationWizardAdvancedInformationTab";
import GroupCreateTransportOptions
  from "@/modules/group/creation-wizard/groupCreateTransportOptions/GroupCreateTransportOptions";
import GroupCreateAccommodationOptions
  from "@/modules/group/creation-wizard/groupCreateAccommodationOptions/GroupCreateAccommodationOptions";
import {useLocalisation} from "@/shared/composables/use-localisation";
import GroupCreationWizardBasicInformationTab
  from "@/modules/group/creation-wizard/GroupCreationWizardBasicInformationTab";
import GroupCreationWizardAdvancedInformationTab
  from "@/modules/group/creation-wizard/GroupCreationWizardAdvancedInformationTab";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import router from '@/router'

import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";


export default {
  name: 'group-creation-wizard',
  components: {
    GroupCreationWizardAdvancedInformationTab,
    BCol,
    BRow,
    BTabs,
    BTab,

    GroupCreationWizardBasicInformationTab,
    GroupCreateAdvancedInfo,
    GroupCreateTransportOptions,
    GroupCreateAccommodationOptions
  },
  directives: {
    Ripple,
  },
  setup() {
    const {route} = useApplicationContext();
    const {t} = useLocalisation();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const groupId = ref(null);
    const lockedSection = ref(true);


    const CURRENT_ROLE = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`].role;
    const canEdit = ref(Roles.roleIsAllowed(CURRENT_ROLE.role, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]));


    if (route.value.params.id) {
      groupId.value = route.value.params.id;
      lockedSection.value = false;
    }

    const updateGroupId = (id) => {
      groupId.value = id;
      lockedSection.value = false;

    };

    const completeGroup = (group_id) => {
      displaySuccessMessage('Complété avec succès');
      router.push(`/groups/${group_id}`);
    }

    const tabIndex = 0;

    return {
      groupId,
      tabIndex,
      updateGroupId,
      completeGroup,
      t,
      lockedSection,
      canEdit
    };
  },
  beforeRouteLeave(to, from, next) {
     next()
    if(false) {
            const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
      if (answer) {
        next();
      } else {
        next(false);
      }
    }

  
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
