<template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
      <div v-if="!hasOneOption" class="mt-4">
        <b-card  title="Ajouter des options d'hébergement" header-tag="header" body-class="text-center" class="mx-5">

          <b-card-text>
            Créer une ou plusieurs options d'hébergement pour votre groupe et ajouter vos inventaires. 
          </b-card-text>

          <b-button variant="primary" v-if="canEdit" @click="showAddOptionModal"> + Ajouter </b-button>

        </b-card>
      </div>
      <div class="mt-2" v-if="hasOneOption">
        <h3 class="float-left">Options d'hébergement ({{accommodationOptions.length}})</h3>
        <b-button variant="success" class="float-right" v-if="canEdit" @click="showAddOptionModal"> + Ajouter option d'hébergement </b-button>
      </div>
      <div v-for="(option, index) in accommodationOptions" :key="option.name">
        <b-card
            border-variant="primary"
            header="Primary"
            header-bg-variant="primary"
            align="center"
            v-if="hasOneOption"
            header-tag="header" class="mt-2"
            no-body
          >
          <template #header>
              <h3 class="text-white">{{index+1}}. {{option.title}}</h3>
              <b-button-group>
                <b-button variant="light" v-if="canEdit" @click="showEditOptionModal(index)">Modifier</b-button>
                <b-button v-b-toggle="'collapse-' + index" variant="outline-light">Voir plus</b-button>
              </b-button-group>
          </template>
          <b-collapse :id="'collapse-' + index" class="p-1" visible>
            <b-row>
              <b-col v-if="option.images" cols="3">
                  <div>
                  
                  <b-img class="mt-2" v-if="option.images" :src="option.images" fluid ></b-img>
                  <b-card-text v-else class="text-justify"><i>Non défini</i></b-card-text>

                </div>
              </b-col>
              <b-col>
                <b-row class="mb-2 mt-2">

                  <b-col cols="3" class="text-align-left">

                    <div class="">
                      <h5>
                        Type d'hébergement
                      </h5>
                      <b-card-text>
                        {{ getAccommodationType(option.type) }}
                      </b-card-text>
                    </div>
                  </b-col>

                  <b-col cols="3" v-if="option.location" class="text-align-left">
                    <div >
                      <h5>
                        Lieu
                      </h5>
                      <b-card-text>
                      
                        {{option.location}}, {{option.country}}
                      </b-card-text>
                    </div>



                  </b-col>
                  <b-col cols="3" class="text-align-left">
                    <div>
                      <h5>
                        Tour opérateur
                      </h5>
                      <b-card-text>
                        {{ getTourOperator(option.operator_tour) }}
                      </b-card-text>
                    </div>
                    <div v-if="option.contract_number" class="mt-1">
                      <h5>
                        Numéro de contract
                      </h5>
                      <b-card-text>
                        {{ option.contract_number }}
                      </b-card-text>
                    </div>
                  </b-col>
                  <b-col cols="3" v-if="option.inventory_release_date || option.final_payment_date" class="text-align-left">
                    <div >
                      <h5>
                        Date limite - Relâche d'inventaire
                      </h5>
                      <b-card-text>
                        {{option.inventory_release_date}}
                      </b-card-text>
                    </div>
                    <div class="mt-1">
                      <h5>
                        Date limite - Paiement final
                      </h5>
                      <b-card-text>
                        {{option.final_payment_date}}
                      </b-card-text>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="option.extra_information || option.description">
                  <b-col cols="6">
                    <div class="text-align-left">
                      <h5>
                        Informations supplémentaires
                      </h5>
                      <b-card-text v-if="option.extra_information">
                        {{ option.extra_information }}
                      </b-card-text>
                      <b-card-text v-else>
                        <i>Non définie</i>
                      </b-card-text>
                    </div>

                  </b-col>
                  <b-col cols="6">
                    <div class="text-align-left">
                      <h5>
                        Description
                      </h5>
                      <b-card-text>
                        {{option.description}}
                      </b-card-text>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">

              <h3 class="float-left">Types de chambres</h3>
              <b-button variant="success" class="float-right" v-if="canEdit" @click="showAddRoomModal(index)" size="sm"> + Ajouter type de chambre </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
              <div class="p-2" v-if="option.rooms && option.rooms.length == 0">
                <h3 class='text-muted' ><i>Aucun type de chambre n'est défini sur cet hébergement</i></h3>
              </div>

              <div v-for="room in option.rooms" :key="room.id">
                <room-card  :can-edit="canEdit" :room-info="room" @openEditRoomModal="showEditRoomModal(room.id, index)"/>
                <group-edit-room-option-modal :ref="`edit-room-option-modal-${room.id}`" :edit-room=room @deleteRoomOption="deleteRoomOption($event, index)" @editRoomOption="editRoomOption($event, index)"/>

              </div>

              </b-col>
            </b-row>
          </b-collapse>
            <group-create-accommodation-options-modal :ref="`edit-accommodation-option-modal-${index}`" action='update' :currentAccommodation=option @deleteAccommodationOption="deleteAccommodationOption($event, index)" @editAccommodationOption="editAccommodationOption($event, index)"/>

        </b-card>
      </div>
      <div class="text-right" v-if="$route.name != 'organisation-group'">
        <b-button variant="primary" v-if="canEdit" class="ml-1" @click="$emit('complete-group',groupId)">Terminé</b-button>
      </div>
    </b-col>
    <group-create-accommodation-options-modal ref='accommodation-option-modal' @createNewAccommodationOption="createGroupAccommodationOption"/>
    <group-create-room-option-modal ref="create-room-option-modal" @createNewRoomOption="createNewRoom"/>

  </b-row>
  
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BImg } from 'bootstrap-vue'
import groupCreationConfig from '@core/data/groupCreationConfig.js';

import GroupCreateAccommodationOptionsModal from './GroupCreateAccommodationOptionsModal'

import GroupCreateRoomOptionModal from './GroupCreateRoomOptionModal'
import GroupEditRoomOptionModal from './GroupEditRoomOptionModal'

import RoomCard from './RoomCard'
import {GroupAccommodationService} from "@/views/groups/services/GroupAccommodationService";

export const groupAccommodationService = new GroupAccommodationService();

import {onMounted} from "@vue/composition-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {reactive, ref, nextTick} from "@vue/composition-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";

import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    BImg,
    GroupCreateAccommodationOptionsModal,
    GroupCreateRoomOptionModal,
    RoomCard,
    GroupEditRoomOptionModal,
    RequiredTextInputField
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: 'NOT_PROVIDED_BY_PARENT_COMPONENT',
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentAccommodationOption: {
        index: null
      }
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup(props, {emit}) {
    const {refOf, forceUpdate} = useApplicationContext();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const {getGroupAccommodationOptions} = useGroupApi();
    let hasOneOption = ref(false)
    let accommodationOptions = reactive([]);
    let currentAccommodationOption = reactive({})
    let currentRole = ref(null);

    currentRole.value = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`].role;

    const fetchGroupAccommodationOptions = async (id) => {
      if (props.groupId != null && props.groupId != "NOT_PROVIDED_BY_PARENT_COMPONENT") {
        try {
          const retrievedAccommodationOptions = await getGroupAccommodationOptions(id);

          toggleAccommodationOptionsList(retrievedAccommodationOptions)

          Object.assign(accommodationOptions, retrievedAccommodationOptions);
          forceUpdate();
        } catch (e) {
          console.error(e)
          displayErrorMessage("Une erreur est arrivée en essayant de retrouver les information du group");
        }
      };
    }

    const createGroupAccommodationOption = async (newOption) => {
      let accommodationOption = Object.assign({}, newOption)
      let currentGroupId = props.groupId;

      return new Promise((resolve,reject) => {

       groupAccommodationService.addAccommodationOption(currentGroupId, accommodationOption).then(response => {

          let matches = (response.headers.location).split( "/" )
          accommodationOption.id = matches[7]

          accommodationOptions.push(accommodationOption);
          toggleAccommodationOptionsList(accommodationOptions);
          displaySuccessMessage('Option ajoutée avec succès');

          //this.displayCreationConfirmation();
          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de sauvegarder l'option");
          reject(true)
        })

      });
    }

    onMounted(() => {
      fetchGroupAccommodationOptions(props.groupId);
    });

    const toggleAccommodationOptionsList = (list) => {
      if (list.length > 0) {
        hasOneOption.value=true;
      }
    }

    const showAddRoomModal = (accommodationOptionIndex) => {
      currentAccommodationOption.index = accommodationOptionIndex;
      refOf('create-room-option-modal').$children[0].show()
    }

    const showAddOptionModal = () =>{ refOf('accommodation-option-modal').$children[0].$children[0].show() }

    const showEditOptionModal = (index) => {
      refOf(`edit-accommodation-option-modal-${index}`)[0].$children[0].$children[0].show()
      currentAccommodationOption.index = index;
    }

    const showEditRoomModal = (id, index) => {
      refOf(`edit-room-option-modal-${id}`)[0].$children[0].show()
      currentAccommodationOption.index = index;
    }

    const createNewRoom = (newRoom) => {
      let room = Object.assign({}, newRoom);
      let currentGroupId = props.groupId;
      let accommodationId = accommodationOptions[currentAccommodationOption.index].id;

      return new Promise((resolve,reject) => {

        groupAccommodationService.addRoomToAccommodationOption(currentGroupId, accommodationId, room).then(response => {
          let matches = (response.headers.location).split( "/" )
          room.id = matches[9]
          
          accommodationOptions[currentAccommodationOption.index].rooms.push(room)
          displaySuccessMessage('Chambre ajoutée avec succès');
          forceUpdate();

          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de sauvegarder la chambre");
          reject(true)
        })

      });
    }

    const editAccommodationOption = (editedOption, index) => {
      let accommodationOption = Object.assign({}, editedOption)
      let currentGroupId = props.groupId;

      return new Promise((resolve,reject) => {

       groupAccommodationService.editAccommodationOption(currentGroupId, accommodationOption).then(response => {
          accommodationOptions[index] = accommodationOption;
          displaySuccessMessage('Option ajoutée avec succès');
          forceUpdate();
          //this.displayCreationConfirmation();
          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de sauvegarder l'option");
          reject(true)
        })

      });
    }
    
    const deleteAccommodationOption = (editedOption, index) => {
      let accommodationOption = Object.assign({}, editedOption)
      let currentGroupId = props.groupId;

      return new Promise((resolve,reject) => {

       groupAccommodationService.deleteAccommodationOption(currentGroupId, accommodationOption).then(response => {
          accommodationOptions.splice(index,1);

          nextTick(() => {
            fetchGroupAccommodationOptions(props.groupId);
            forceUpdate();
          })
          forceUpdate();

          displaySuccessMessage('Option supprimée avec succès');

          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de sauvegarder l'option");
          reject(true)
        })

      });
    }

    const editRoomOption = (editedRoomOption, index) => {

      let room = Object.assign({}, editedRoomOption);
      let currentGroupId = props.groupId;
      let accommodationId = accommodationOptions[currentAccommodationOption.index].id;


      return new Promise((resolve,reject) => {
        groupAccommodationService.editRoomOption(currentGroupId, accommodationId, room).then(response => {

            let currentRoomIndex = accommodationOptions[currentAccommodationOption.index].rooms.findIndex(currentRoom => {
              return currentRoom.id === room.id
            })
            accommodationOptions[currentAccommodationOption.index].rooms[currentRoomIndex] = (room)

            forceUpdate();
            displaySuccessMessage('Option modifiée avec succès');
            resolve(true)
          }).catch(error => {
            console.error(error)
            displayErrorMessage("Une erreur est arrivée en essayant de sauvegarder l'option");
            reject(true)
          })
        })
    }

    const deleteRoomOption = (editedRoomOption, index) => {

      let room = Object.assign({}, editedRoomOption);
      let currentGroupId = props.groupId;
      let accommodationId = accommodationOptions[currentAccommodationOption.index].id;


      return new Promise((resolve,reject) => {
        groupAccommodationService.deleteRoomOption(currentGroupId, accommodationId, room).then(response => {

          let currentRoomIndex = accommodationOptions[currentAccommodationOption.index].rooms.findIndex(currentRoom => {
              return currentRoom.id === room.id
            })
            accommodationOptions[currentAccommodationOption.index].rooms.splice(currentRoomIndex,1);
          nextTick(() => {
            fetchGroupAccommodationOptions(props.groupId);
            forceUpdate();
          })

            displaySuccessMessage('Option modifiée avec succès');
            resolve(true)
          }).catch(error => {
            console.error(error)
            displayErrorMessage("Une erreur est arrivée en essayant de sauvegarder l'option");
            reject(true)
          })
        })
    }


    const getAccommodationType = (type) => {
      const accommodationType = groupCreationConfig.accommodationType.find(x => x.value === type)
      return accommodationType?.text;
    }

    const getTourOperator = (tourOperatorId) => {
      const tourOperator = groupCreationConfig.accommodationTourOperators.find(x => x.value === tourOperatorId)
      return tourOperator?.text;
    }

    const isOrgAdmin = () => {
      return Roles.roleIsAllowed(currentRole.value.role, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role])
    };

    return {
      getAccommodationType,
      getTourOperator,
      hasOneOption,
      accommodationOptions,
      createGroupAccommodationOption,
      showAddRoomModal,
      currentAccommodationOption,
      createNewRoom,
      showAddOptionModal,
      showEditOptionModal,
      showEditRoomModal,
      editAccommodationOption,
      editRoomOption,
      deleteRoomOption,
      deleteAccommodationOption,
      isOrgAdmin
    }

  },
  methods: {

  }
}
</script>

<style>
  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-white {
    color:white;
  }

  .flight-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
  }

  .airport-code {
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
  }

  .flight-time {
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
  }

  .airport-city, .flight-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-top: 2px
  }

  .small-top-padding {
    padding: 0;
    padding-top:10px;
  }


</style>
