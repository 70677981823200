<template>
  <validation-observer ref="refFormObserver">
    <b-modal
      id="modal-select2"
      title="Option d'hébergement"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @close="resetMedia"
      @cancel="resetMedia"
      @ok="submitCreateNewAccommodationOption"
      @show="fillDataIfProvided"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col
            v-if="action == 'update' && !canBeDeleted"
            cols="12"
            class="mb-2"
          >
            <b-alert show variant="danger">
              <p class="p-1">
                Il ne doit pas rester de type de chambres dans l'option
                d'hébergement pour pouvoir la supprimer.
              </p>
            </b-alert>
          </b-col>
          <b-col v-if="action == 'update'" cols="12" class="mb-2">
            <b-button
              variant="danger"
              @click="deleteAccommodationtOption"
              class="float-right"
              size="sm"
            >
              Supprimer
            </b-button>
          </b-col>
          <b-col md="6">
            <!--
            <required-text-input-field
              name="Group name"
              label-for="group-name"
              placeholder="Escapade à Paris"
            />
            -->
            <b-form-group
              label="Nom de l'hébergement"
              label-for="accommodation-option-name"
            >
              <b-form-input
                id="`option-name-%{option.index}`"
                v-model="accommodation.title"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Type d'hébergement"
              label-for="accommodation-type"
            >
              <v-select
                id="accommodation-type"
                :options="groupCreationConfig.accommodationType"
                :reduce="(option) => option.value"
                label="text"
                v-model="accommodation.type"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- NO NEEDED FOR TRANSAT 
        <b-row>
          

          <b-col md="3">

            <b-form-group
              label="Ville"
              label-for="accommodation-location"
            >

              <b-form-input placeholder="Québec" v-model="accommodation.location"/>

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Pays"
              label-for="accommodation-country"
            >
              <v-select
                id="accommodation-country"
                :options="countryOptions"
                :reduce="option => option.value"
                label="text"
                v-model="accommodation.country"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
          <h1 class="section-label mx-0 mt-2 mb-2">
            Détails de l'option
          </h1>
          </b-col>

        </b-row>
        
        <b-row>
          -->
        <!-- NO NEED FOR TRANSAT 
          <b-col md="6">
            <b-form-group
              label="Date limite - Relâche d'inventaire"
              label-for="accommodation-inventory-release-date"
            >
              <b-form-input
                id="accommodation-inventory-release-date"
                class="mb-1"
                type="date"
                v-model="accommodation.inventory_release_date"
                name="accommodation-inventory-release-date"
                locale="fr"
                placeholder="Aucune date sélectionnée"
              />  
            </b-form-group>
          </b-col>
          -->
        <b-row>
          <!--
          <b-col md="6">
            <b-form-group
              label="Date limite - Paiement final"
              label-for="accommodation-final-payment-date"
            >
              <b-form-input
                id="accommodation-final-payment-date"
                class="mb-1"
                type="date"
                v-model="accommodation.final_payment_date"
                name="accommodation-final-payment-date"
                locale="fr"
                max="2100-01-01"
                placeholder="Aucune date sélectionnée"
              />
            </b-form-group>
          </b-col>
          -->

          <b-col md="6">
            <b-form-group label="Tour opérateur" label-for="tour-operator">
              <v-select
                id="tour-operator"
                :options="groupCreationConfig.accommodationTourOperators"
                :reduce="(option) => option.value"
                label="text"
                v-model="accommodation.operator_tour"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Numéro de contrat" label-for="contract-number">
              <b-form-input
                v-model="accommodation.contract_number"
                name="Numéro de contrat"
                label="Numéro de contrat"
                label-for="contract-number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- No need for transat
          <b-row class="mt-2">
          <b-col md="6">

            <b-form-group
              label="Description"
              label-for="accommodation-description"
            >

              <b-form-textarea
                id="textarea-small"
                size="sm"
                rows="5"
                placeholder="description"
                v-model="accommodation.description"
                :class=" descriptionLength>255? 'is-invalid':''"
                
              >
              </b-form-textarea>
         
              <small class="error-label"  >{{ descriptionLength > 255 ? 'La description dépasse limite de caractères' : '' }}</small>

            </b-form-group>
          </b-col>
          <b-col md="6">

            <b-form-group
              label="Informations supplémentaires"
              label-for="accommodation-extra-information"
            >

              <b-form-textarea
                  id="textarea-small"
                  size="sm"
                  rows="5"
                  placeholder="Informations supplémentaires"
                  v-model="accommodation.extra_information"
                  :class=" extraInfoLength>255? 'is-invalid':''"

                >
              </b-form-textarea>
              <small class="error-label"  >{{ extraInfoLength > 255 ? 'La description dépasse limite de caractères' : '' }}</small>

            </b-form-group>
          </b-col>

          </b-row>
          -->
        <b-row>
          <b-col cols="12" class="mb-2">
            <h1 class="section-label mx-0 mt-2">Image de l'hébergement</h1>
          </b-col>
          <b-col md="6">
            <b-form-file
              ref="file-upload"
              v-model="images.file1"
              :state="Boolean(images.file1)"
              placeholder="Veuillez sélectionner une image..."
              drop-placeholder="Déposer l'image ici..."
              browse-text="Parcourir"
              @change="uploadImageToAccommodation"
            ></b-form-file>
            <small class="form-text text-muted"
              >Poids maximal de l'image: 5 Mo</small
            >
            <div class="mt-3">
              Nom du fichier: {{ images.file1 ? images.file1.name : "" }}
            </div>
          </b-col>
          <b-col md="6">
            <b-img :src="accommodation.images" fluid></b-img>
            <!-- <input type="file" accept="image/*" @input="uploadImage2($event)" id="file-input"> -->
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormTextarea,
  BTabs,
  BFormFile,
  BTab,
  BFormInvalidFeedback,
  BImg,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import groupCreationConfig from "@core/data/groupCreationConfig.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useGroupApi } from "@/modules/group/composables/use-group-api";
import Vue from "vue";
var countries = require("i18n-iso-countries");
import { ValidationObserver } from "vee-validate";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";
import { useValidatorsWithAutoFormDataCleanUp } from "@/shared/composables/use-validators";
import { required } from "@validations";
import { reactive, computed, ref, nextTick } from "@vue/composition-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";

export default {
  components: {
    BImg,
    BAlert,
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    ValidationObserver,
    RequiredTextInputField,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      groupCreationConfig,
    };
  },
  props: {
    currentAccommodation: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    action: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { displayErrorMessage } = useToastNotification();
    const { uploadImage } = useGroupApi();
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

    let canBeDeleted = ref(true);

    let initialState = {
      title: null,
      type: null,
      location: null,
      country: null,
      inventory_release_date: null,
      final_payment_date: null,
      operator_tour: null,
      extra_information: null,
      description: null,
      rooms: [],
      images: null,
      media_id: null,
      contract_number: null,
    };

    const accommodation = reactive({ ...initialState });

    let images = reactive({});
    let mediaId = ref(null);

    const resetMedia = () => {
      mediaId.value = null;
      images.file1 = null;
      accommodation.images = null;
    };

    function resetAccommodation() {
      Object.assign(accommodation, initialState);
      accommodation.rooms = [];
    }

    function setAccommodation() {
      Object.assign(accommodation, props.currentAccommodation);
    }

    const fillDataIfProvided = () => {
      if (props.action === "update") {
        setAccommodation();
      } else {
        resetAccommodation();
      }
    };

    const submitCreateNewAccommodationOption = async (event) => {
      try {
        accommodation.media_id = mediaId.value;
        if (props.action === "update") {
          emit("editAccommodationOption", accommodation);
        } else {
          emit("createNewAccommodationOption", accommodation);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const descriptionLength = computed(() => {
      let length = 0;
      if (accommodation && accommodation.description) {
        length = accommodation.description.length;
      }
      return length;
    });

    const extraInfoLength = computed(() => {
      let length = 0;
      if (accommodation && accommodation.extra_information) {
        length = accommodation.extra_information.length;
      }
      return length;
    });

    const deleteAccommodationtOption = async (event) => {
      let rooms = props.currentAccommodation.rooms;
      if (rooms && rooms.length > 0) {
        canBeDeleted.value = false;
      } else {
        canBeDeleted.value = true;

        emit("deleteAccommodationOption", accommodation);
      }
    };

    const uploadImageToAccommodation = async (event) => {
      let file = event.target.files[0];

      if (file) {
        let data = new FormData();
        data.append("name", file.name);
        data.append("type", "image");
        data.append("file", event.target.files[0]);

        try {
          let response = await uploadImage(data);
          let imageUrl = "";

          if (
            Object.hasOwn(response.data, "media_url") &&
            Object.hasOwn(response.data, "media_id")
          ) {
            imageUrl = response.data.media_url;
            mediaId.value = response.data.media_id;
          } else {
            imageUrl = response.headers.location;
          }

          Vue.set(accommodation, "images", imageUrl);
        } catch (e) {
          console.error(e);
          displayErrorMessage("Le téléversement de l'image a échoué.");
          images.file1 = null;
          this.$refs["file-upload"].reset();
        }
      }
    };

    let countriesObject = countries.getNames("fr", { select: "official" });
    let countryOptions = Object.keys(countriesObject).map((key) => ({
      text: countriesObject[key],
      value: countriesObject[key],
    }));

    const { refFormObserver, resetForm } =
      useValidatorsWithAutoFormDataCleanUp(accommodation);

    return {
      submitCreateNewAccommodationOption,
      accommodation,
      images,
      uploadImageToAccommodation,
      fillDataIfProvided,
      countryOptions,
      refFormObserver,
      required,
      descriptionLength,
      extraInfoLength,
      canBeDeleted,
      deleteAccommodationtOption,
      resetMedia,
      mediaId,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
