<template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
      <div v-if="!hasOneOption" class="mt-4">
        <b-card  title="Ajouter des options de transport" header-tag="header" body-class="text-center" class="mx-5">

          <b-card-text>
            Créer une ou plusieurs options de transport pour votre groupe et ajouter vos inventaires. 
          </b-card-text>

          <b-button  variant="primary" @click="showAddOptionModal">Ajouter</b-button>
        </b-card>
      </div>
      <div class="mt-2" v-if="hasOneOption">
        <h3 class="float-left">Options de transport ({{transportOptions.length}})</h3>
        <b-button variant="success" v-if="canEdit" class="float-right" @click="showAddOptionModal"> + Ajouter option transport </b-button>
      </div>
      <div v-for="(option, index) in transportOptions" :key="option.name">
        <b-card
            border-variant="primary"
            header="Primary"
            header-bg-variant="primary"
            align="center"
            v-if="hasOneOption"
            header-tag="header" class="mt-2"
            no-body
          >
          <template #header>
              <h3 class="text-white">{{index+1}}. {{option.name}}</h3>
              <b-button-group>
                <b-button variant="outline-light" v-if="canEdit" @click="showEditOptionModal(index)">Modifier</b-button>
                <b-button v-b-toggle="'collapse-' + index" variant="outline-light">Détails</b-button>
          
              </b-button-group>
          </template>
          
          <b-collapse :id="'collapse-' + index" class="p-1" visible>

            <b-row class="mb-2 mt-2">
              <b-col cols="2" class="text-align-left">
                <div>
                  <h5 class=" ">
                    Type de transport
                  </h5>
                  <b-card-text>
                    Vol <!-- {{option.transportType}} -->
                  </b-card-text>
                </div>

              </b-col>

              <b-col cols="2" class="text-align-left">
                <div class="">
                  <h5 class=" ">
                    Inventaire
                  </h5>
                  <b-card-text>
                    {{option.seats}}
                  </b-card-text>
                </div>
              </b-col>
              <b-col cols="2" class="text-align-left">
                <div>
                  <h5 class=" ">
                    Sélection de siège
                  </h5>
                  <b-card-text>
                    {{ getSeatSelectionOption(option.seatSelection) }}
                  </b-card-text>
                </div>
              </b-col>
              <b-col cols="2" class="text-align-left">
                <div v-if="option.tourOperator" >
                  <h5 class=" ">
                   Tour opérateur
                  </h5>
                  <b-card-text>
                    {{ getTourOperator(option.tourOperator) }}
                  </b-card-text>
                </div>
              </b-col>
              <b-col cols="2" class="text-align-left">
                <div v-if="isOrgAdmin() && option.contractNumber">
                  <h5>
                   Numéro de contrat
                  </h5>
                  <b-card-text>
                    {{ option.contractNumber }}
                  </b-card-text>
                </div>
              </b-col>
              <b-col cols="3" v-if="option.seatReleaseDate" class="text-align-left">
                <div>
                  <h5 class=" ">
                    Date limite - relâche inventaire
                  </h5>
                  <b-card-text>
                    {{option.seatReleaseDate}}
                  </b-card-text>
                </div>
                <div v-if="option.paymentLimitDate" class="mt-1">
                  <h5 class=" ">
                    Date Limite - Paiement final
                  </h5>
                  <b-card-text>
                    {{option.paymentLimitDate}}
                  </b-card-text>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-row class="mb-1">
                  <b-col cols="12">
                    <h3 class="float-left">Départ</h3>
                    <b-button variant="success" class="float-right" v-if="canEdit" @click="showAddFlightModal('departures',index)" size="sm"> + Ajouter segment aller</b-button>
                  </b-col>
                </b-row>
                <div class="p-2" v-if="option.departures && option.departures.length == 0">
                  <h4 class='text-muted' ><i>Aucun segment de départ</i></h4>
                </div>
              <div v-for="flight, flightIndex in option.departures" :key="flight.transport_number">
                <flight-card :flight-info="flight" :can-edit="canEdit" @openEditFlightModal="showEditFlightModal(flight.id, index, 'departures')"/>
                <flight-card-modal :ref="`flight-card-edit-modal-${flight.id}`" action="update" :flight="flight" @deleteFlightSegment="deleteFlightSegment($event, index, flightIndex)" @editFlightSegment="editFlightSegment($event, index, flightIndex)" :group-id="groupId" />

              </div>


              </b-col>
              <b-col cols="6" >
                <b-row class="mb-1">
                  <b-col cols="12">
                    <h3 class="float-left">Retour</h3>
                    <b-button variant="success" class="float-right" v-if="canEdit" @click="showAddFlightModal('returns',index)" size="sm"> + Ajouter segment retour </b-button>
                  </b-col>
                </b-row>
                <div class="p-2" v-if="option.returns && option.returns.length == 0">
                  <h4 class='text-muted' ><i>Aucun segment de retour</i></h4>
                </div>
              <div v-for="flight, flightIndex in option.returns" :key="flight.transport_number">
                <flight-card :flight-info="flight" :can-edit="canEdit"  @openEditFlightModal="showEditFlightModal(flight.id, index, 'returns')"/>
                <flight-card-modal :ref="`flight-card-edit-modal-${flight.id}`" action="update" :flight="flight" @deleteFlightSegment="deleteFlightSegment($event, index, flightIndex)" @editFlightSegment="editFlightSegment($event, index, flightIndex)" :group-id="groupId" />

              </div>

              </b-col>
            </b-row>
          </b-collapse>
          <group-create-transport-options-modal action='update' :ref="`edit-transport-option-modal-${index}`" :current-transportation=option @deleteTransportOption="deleteTransportOption($event, index)" @editTransportOption="editTransportOption($event, index)"/>

        </b-card>

      </div>

      <div class="text-right">

        <b-button variant="primary" class="ml-1" @click="$emit('next-tab')">Étape suivante</b-button>
      </div>
    </b-col>
    <group-create-transport-options-modal ref='transport-option-modal' @createNewTransportOption="createGroupTransportOption"/>

    <flight-card-modal :group-id="groupId" ref="flight-segment-modal" action="new" @createNewFlightSegment="createFlightSegment" />

  </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel } from 'bootstrap-vue'
import FlightCard from './FlightCard.vue'
import GroupCreateTransportOptionsModal from './GroupCreateTransportOptionsModal.vue'
import GroupEditTransportOptionsModal from './GroupEditTransportOptionsModal.vue'
import FlightCardModal from './FlightCardModal.vue'
import { GroupTransportationService } from '@/views/groups/services/GroupTransportationService.js'
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {onMounted, nextTick, reactive, ref} from "@vue/composition-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useLocalisation} from "@/shared/composables/use-localisation";
import groupCreationConfig from '@/@core/data/groupCreationConfig'
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";

export const groupTransportationService = new GroupTransportationService();

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    FlightCard,
    GroupCreateTransportOptionsModal,
    GroupEditTransportOptionsModal,
    FlightCardModal,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: 'NOT_PROVIDED_BY_PARENT_COMPONENT',
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentFlightOption: {
        direction: null,
        transportOptionIndex: null
      },
      currentTransportOption: {
        index: null,
      },
      editElement: {}
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup(props) {
    const {refOf, forceUpdate} = useApplicationContext();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const {getGroupTransportOptions} = useGroupApi();
    const {t} = useLocalisation();
    let currentRole = ref(null);

    currentRole.value = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`].role;

    let hasOneOption = ref(false)
    let transportOptions = reactive([]);

    let currentFlightOption = reactive({
      direction: null,
      transportOptionIndex: null,
      flightSegmentId: null
    });

    let currentTransportOption = reactive({
      index: null,
    });

    const fetchGroupTransportOptions = async (id) => {
      if (props.groupId != null && props.groupId != "NOT_PROVIDED_BY_PARENT_COMPONENT") {
        try {
          const retrievedTransportOptions = await getGroupTransportOptions(id);

          toggleTransportOptionsList(retrievedTransportOptions)

          Object.assign(transportOptions, retrievedTransportOptions);
          forceUpdate();
        } catch (e) {
          console.error(e)
          displayErrorMessage("Une erreur est arrivée en essayant de retrouver les information du group");
        }
      };
    }
    onMounted(() => {
      fetchGroupTransportOptions(props.groupId);
    });

    //Methods
    const createGroupTransportOption = async (newOption) => {
      let transportOption = Object.assign({}, newOption)
      let currentGroupId = props.groupId

      return new Promise((resolve,reject) => {
       groupTransportationService.addTransportOption(currentGroupId, transportOption).then(response => {
          nextTick(() => {
            fetchGroupTransportOptions(props.groupId)
            forceUpdate();
          })

          toggleTransportOptionsList(transportOptions);
          //this.displayCreationConfirmation();
          refOf('transport-option-modal').$children[0].$children[0].hide()
          displaySuccessMessage('Option ajoutée avec succès');

          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de sauvegarder l'option");
          reject(true)
        })

      });

    }

    const createFlightSegment = async (newFlightSegment) => {
      let flightSegment = Object.assign({}, newFlightSegment);

      let index = currentFlightOption.transportOptionIndex;
      let direction = currentFlightOption.direction;
      let currentGroupId = props.groupId
      let transportOptionId = transportOptions[index].id


      return new Promise((resolve, reject) => {
        groupTransportationService.addFlightToTransportOption(currentGroupId, transportOptionId, direction, flightSegment).then(response => {

          transportOptions[index][direction].push(flightSegment)

          nextTick(() => {
            fetchGroupTransportOptions(props.groupId)
            forceUpdate();
          })
          //this.displayCreationConfirmation();

          resolve(true)
        }).catch(error => {
          console.error(error)
          //this.displayCreationConfirmation(error)
          reject(true)
        })
      });
    }

    const showEditFlightModal = (flight_id, index, direction) => {
      refOf(`flight-card-edit-modal-${flight_id}`)[0].$children[0].$children[0].show()
      currentFlightOption.flightSegmentId = flight_id;
      currentFlightOption.direction = direction;
      currentFlightOption.transportOptionIndex = index;
    }

    const showAddFlightModal = (direction, transportOptionIndex) => {
      currentFlightOption.direction = direction;
      currentFlightOption.transportOptionIndex = transportOptionIndex;
      refOf('flight-segment-modal').$children[0].$children[0].show()
    }

    const showEditOptionModal = (index) => {
      refOf("edit-transport-option-modal-"+index)[0].$children[0].$children[0].show()
      currentTransportOption.index = index;
    }

    const toggleTransportOptionsList = (list) => {
      if (list.length > 0) {
        hasOneOption.value=true;
      }
    }

    const editTransportOption = (editedOption, index) => {
      let transportOption = Object.assign({}, editedOption)
      let currentGroupId = props.groupId;
      
      let originalId = transportOptions[index].id
      return new Promise((resolve,reject) => {
       groupTransportationService.editTransportOption(currentGroupId, originalId, transportOption).then(response => {
          transportOptions[index] = transportOption;
          displaySuccessMessage('Option modifiée avec succès');
          forceUpdate();
          //this.displayCreationConfirmation();
          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de modifier l'option");
          reject(true)
        })
      });
    }

    const deleteTransportOption = (editedOption, index) => {
      let currentGroupId = props.groupId;
      let originalId = transportOptions[index].id
      return new Promise((resolve,reject) => {
       groupTransportationService.deleteTransportOption(currentGroupId, originalId).then(response => {
          transportOptions.splice(index, 1);

          displaySuccessMessage('Option modifiée avec succès');
          nextTick(() => {
            fetchGroupTransportOptions(currentGroupId);
            forceUpdate();
          })
          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de modifier l'option");
          reject(true)
        })
      });
    }

    const editFlightSegment = (editedFlightSegment, index, flightIndex) => {
      let flightSegmentId = currentFlightOption.flightSegmentId;
      let currentGroupId = props.groupId;
      let optionId = transportOptions[index].id;
      let direction = currentFlightOption.direction;

      return new Promise((resolve,reject) => {
       groupTransportationService.editFlightSegment(currentGroupId, optionId,flightSegmentId, editedFlightSegment).then(response => {
          transportOptions[index][direction][flightIndex] =editedFlightSegment;

          displaySuccessMessage('Option modifiée avec succès');
          forceUpdate();
          //this.displayCreationConfirmation();
          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de modifier l'option");
          reject(true)
        })
      });
    }

    const deleteFlightSegment = (editedFlightSegment, index, flightIndex) => {
      let flightSegmentId = currentFlightOption.flightSegmentId;
      let currentGroupId = props.groupId;
      let optionId = transportOptions[index].id;
      let direction = currentFlightOption.direction;

      return new Promise((resolve,reject) => {
       groupTransportationService.deleteFlightSegment(currentGroupId, optionId, flightSegmentId).then(response => {
          transportOptions[index][direction].splice(flightIndex, 1);
          nextTick(() => {
            fetchGroupTransportOptions(props.groupId)
            forceUpdate();
          })
          displaySuccessMessage('Option supprimée avec succès');
          //this.displayCreationConfirmation();
          resolve(true)
        }).catch(error => {
          console.error(error)
          displayErrorMessage("Une erreur est arrivée en essayant de supprimer l'option");
          reject(true)
        })
      });
    }

    const getSeatSelectionOption = (optionId) => groupCreationConfig.seatsSelection.find(x => x.value === optionId)?.text || optionId
    const getTourOperator = (tourOperatorId) => groupCreationConfig.tourOperators.find(x => x.value === tourOperatorId)?.text || tourOperatorId

    return {
      transportOptions,
      hasOneOption,
      createGroupTransportOption,
      showEditOptionModal,
      createFlightSegment,
      showEditFlightModal,
      showAddFlightModal,
      t,
      refOf,
      editTransportOption,
      editFlightSegment,
      deleteFlightSegment,
      deleteTransportOption,
      getSeatSelectionOption,
      getTourOperator,
      currentRole,
      Roles,
    }
  },
  methods: {
    showAddOptionModal() {
      this.$refs['transport-option-modal'].$children[0].$children[0].show()
    },
    isOrgAdmin() {
      return Roles.roleIsAllowed(this.currentRole.role, [this.Roles.ORGANIZATION_ADMIN.role, this.Roles.ORGANIZATION_COORDINATOR.role])
    },
  }
}
</script>

<style>
  .text-align-left {
    text-align: left;
  }

  .text-white {
    color:white;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
</style>
