<template>
    <b-modal
      id="modal-select2"
      title="Information avancée"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @ok='submitCreateNewRoomOption'
      no-close-on-backdrop
    >
      <b-form
        ref="form"
      >

        <!-- Row Loop -->
        <b-row
          ref="row"
          class="room-type-item"
        >
        <b-col md="10">
          <b-tabs>
            <b-tab>
              <template #title>
                <feather-icon icon="HomeIcon" />
                <span>Type hébergement info</span>
              </template>
              <b-row>

                <!-- Room Category -->
                <b-col md="6">
                  <b-form-group
                    label="Catégorie de la chambre"
                    label-for="room-category"
                  >
                    <b-form-input
                      id="room-category"
                      type="text"
                      placeholder="Chambre Deluxe"
                      v-model="room.room_category"
                    />
                  </b-form-group>
                </b-col>

                <!-- inventory -->
                <b-col md="6">
                  <b-form-group
                    label="Inventaire"
                    label-for="inventory"
                  >
                    <b-form-input
                      id="inventory"
                      type="number"
                      placeholder="32"
                      v-model="room.inventory"
                    />
                  </b-form-group>
                </b-col>
                </b-row>

                <!--
                <b-row>

                <b-col md="6">
                  <b-form-group
                    label="Lien internet"
                    label-for="url"
                  >
                    <b-form-input
                      id="room-url"
                      type="text"
                      placeholder="Lien internet"
                      v-model="room.url"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Room Option"
                    label-for="url"
                  >

                    <v-select
                      id="room-option"
                      ref="room-option"
                      v-model="room.option"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="groupCreationConfig.roomOption"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                      :reduce="text => text.value"           
                    />

                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Inclusions"
                    label-for="room-inclusions"
                  >
                    <b-form-tags
                      v-model="room.inclusions"
                      input-id="tags-basic"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>


                <b-col md="6">
                  <b-form-group
                    label="Exclusions"
                    label-for="exclusions"
                  >
                    <b-form-tags
                      v-model="room.exclusions"
                      input-id="tags-basic"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Caractéristiques"
                    label-for="characteristics"
                  >
                    <b-form-tags
                      v-model="room.characteristics"
                      input-id="tags-basic"
                      class="mb-2"
                    />
                  </b-form-group>

                </b-col>

              </b-row>
              
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Description"
                    label-for="room-description"
                  >

                    <b-form-textarea
                      id="room-inclusions"
                      placeholder=""
                      rows="4"
                      v-model="room.description"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                
                <b-col md="12">
                  <b-form-group
                    label="Informations supplémentaires"
                    label-for="room-extra-information"
                  >

                    <b-form-textarea
                      id="room-extra-information"
                      placeholder=""
                      rows="4"
                      v-model="room.extra_information"

                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              -->



            </b-tab>
          </b-tabs>

        </b-col>

        </b-row>
      </b-form>
    </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup, BFormTags,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormTextarea,
  BTabs,
  BFormFile,
  BTab,
  BFormInvalidFeedback,
  
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import groupCreationConfig from '@core/data/groupCreationConfig.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup, 
    BFormCheckbox,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormTags
    },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      groupCreationConfig,
      room: {
        inventory: null,
        accommodationType: null,
        addressLine: null,
        location: null,
        country: null,
        inventoryReleaseDate: null,
        depositAmount: null,
        paymentLimitDate: null,
        tourOperator: null,
        extra_information: null,
        description: null,
        option: null,
        room_category: null,
      }
    }
  },
  methods: {
    submitCreateNewRoomOption (event) {
      if (this.room.inventory == undefined || this.room.inventory == null || this.room.inventory === "") {
        this.room.inventory = 0;
      }

      this.$emit('createNewRoomOption', this.room)

      this.room = {
        inventory: null,
        accommodationType: null,
        addressLine: null,
        location: null,
        country: null,
        inventoryReleaseDate: null,
        depositAmount: null,
        paymentLimitDate: null,
        tourOperator: null,
        extra_information: null,
        description: null,
        option: null,
        room_category: null,
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
