<template>
  <b-tab :title="t('group.creation_steps.step_1')">
    <b-row class="match-height">
      <b-col lg="12" md="12">
        <div v-if="!existingGroupId" class="mt-4">
          <b-card
            :title="t('group.modal.basic_info.title')"
            header-tag="header"
            body-class="text-center"
            class="mx-5"
          >
            <b-card-text>{{ t('group.modal.basic_info.description') }}</b-card-text>
            <b-button variant="primary" @click="showBasicInformationModal">{{ t('button.create') }}</b-button>
            <group-basic-information-modal
              action="create"
              ref="group-basic-information-modal-ref"
              @monica-group-saved="updateCurrentGroupId"/>
          </b-card>
        </div>

        <div v-else>
          <group-basic-information-card :group-id="existingGroupId"/>
          
          <div class="text-right">
            <b-button variant="primary" class="ml-1" @click="$emit('next-tab')">{{ t('button.next_step') }}</b-button>
          </div>
        </div>
      </b-col>
      <b-col lg="6" md="6"></b-col>
    </b-row>
  </b-tab>
</template>

<script>
import {BTabs, BTab, BRow, BCol, BCard, BCardText, BButton} from 'bootstrap-vue';
import GroupBasicInformationModal
  from "@/modules/group/components/GroupBasicInformationModal";
import {onMounted, reactive, ref} from "@vue/composition-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useLocalisation} from "@/shared/composables/use-localisation";
import GroupBasicInformationCard from "@/modules/group/components/GroupBasicInformationCard";

export default {
  name: 'group-creation-wizard-basic-information-tab',
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupBasicInformationModal,
    GroupBasicInformationCard,
    
  },
  props: {
    groupId: {
      type: String | undefined,
      required: false,
      default: null,
    }
  },
  setup(props, {emit}) {
    const {refOf} = useApplicationContext();
    const {t} = useLocalisation();

    let basicInformation = reactive({});
    const existingGroupId = ref(null);

    onMounted(() => {
      if (props.groupId) {
        updateCurrentGroupId(props.groupId);
      }
    });

    const showBasicInformationModal = () => {
      refOf('group-basic-information-modal-ref').$children[0].$children[0].show();
    };

    const updateCurrentGroupId = async (id) => {
      existingGroupId.value = id;

      emit('currentGroupId', id)
    };


    return {
      existingGroupId,
      basicInformation,

      t,
      showBasicInformationModal,
      updateCurrentGroupId,
    };
  },
};
</script>
