<template>
  <!-- <b-tab :title="t('group.creation_steps.step_2')" :disabled="!groupId"> -->

  <b-tab :title="t('group.creation_steps.step_2')" :disabled=disabled >
    <b-row class="match-height">
      <b-col lg="12" md="12">
        <div v-if="!hasAlreadyAdvancedInformation" class="mt-4">
          <b-card
            :title="t('group.modal.advanced_info.title')"
            header-tag="header"
            body-class="text-center"
            class="mx-5"
          >
            <b-card-text>{{ t('group.modal.advanced_info.description') }}</b-card-text>
            <b-button variant="primary" @click="showAdvancedInfoModal">{{ t('button.add') }}</b-button>
            <group-advanced-information-modal
              v-if="groupId"
              action="create"
              :group-id="groupId"
              ref="group-advanced-information-modal-ref"
              @monica-group-saved="toggleHasAlreadyAdvancedInformation"
            />
          </b-card>
        </div>

        <div v-if="hasAlreadyAdvancedInformation">
          <group-advanced-information-card v-if="groupId" :group-id="groupId"/>
        </div>


        <div class="text-right">
          <b-button variant="primary" class="ml-1" @click="$emit('next-tab')">{{ t('button.next_step') }}</b-button>
        </div>
      </b-col>
      <b-col lg="6" md="6"></b-col>
    </b-row>
  </b-tab>
</template>

<script>
import {BTabs, BTab, BRow, BCol, BCard, BCardText, BButton} from 'bootstrap-vue';
import {GroupCreationService} from '@/views/groups/services/GroupCreationService';

export const groupCreationService = new GroupCreationService();
import store from '@/store';
import GroupAdvancedInformationModal from "@/modules/group/components/GroupAdvancedInformationModal";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useLocalisation} from "@/shared/composables/use-localisation";
import {onMounted, ref} from "@vue/composition-api/dist/vue-composition-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import GroupAdvancedInformationCard from "@/modules/group/components/GroupAdvancedInformationCard";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupAdvancedInformationModal,
    GroupAdvancedInformationCard,
  },
  props: {
    groupId: {
      type: String | undefined,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {refOf} = useApplicationContext();
    const {t} = useLocalisation();

    const hasAlreadyAdvancedInformation = ref(false);
    const hasAlreadyMediaInformation = ref(false);

    const showAdvancedInfoModal = () => {
      refOf('group-advanced-information-modal-ref').$children[0].show();
    };


    onMounted(() => {
      if (props.groupId) {
        hasAlreadyAdvancedInformation.value = true
      }
    });


    const toggleHasAlreadyAdvancedInformation = () => {
      hasAlreadyAdvancedInformation.value = true;
    };

    const createMediaInfoGroup = () => {
      hasAlreadyMediaInformation.value = true;
    };

    return {
      hasAlreadyAdvancedInformation,
      hasAlreadyMediaInformation,

      t,
      toggleHasAlreadyAdvancedInformation,
      showAdvancedInfoModal,
      createMediaInfoGroup,
    };
  },
};
</script>

<style>

</style>
