var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-modal',{ref:"flight-modal",attrs:{"id":"modal-select2","title":"Ajouter un segment","ok-title":"Enregistrer","cancel-title":"Annuler ","cancel-variant":"outline-secondary","size":"lg","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)},"show":_vm.fillDataIfProvided,"cancel":_vm.resetForm}},[_c('b-form',[_c('b-row',[(_vm.action == 'update')?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.deleteSegment}},[_vm._v(" Supprimer ")])],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Aéroport de départ","label-for":"departure-airport"}},[_c('validation-provider',{attrs:{"name":"Aéroport de départ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.airports,"label":"label","reduce":function (airport){ return airport.iata; },"rules":"required"},on:{"search":_vm.searchAirports},model:{value:(_vm.flightSegment.departureStation),callback:function ($$v) {_vm.$set(_vm.flightSegment, "departureStation", $$v)},expression:"flightSegment.departureStation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Aéroport d'arrivée","label-for":"arrival-airport"}},[_c('validation-provider',{attrs:{"name":"Aéroport de d'arrivée","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.airports,"label":"label","reduce":function (airport){ return airport.iata; }},on:{"search":_vm.searchAirports},model:{value:(_vm.flightSegment.arrivalStation),callback:function ($$v) {_vm.$set(_vm.flightSegment, "arrivalStation", $$v)},expression:"flightSegment.arrivalStation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date et heure de départ","label-for":"departure-date-time"}},[_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"departure-date-time","type":"datetime-local","locale":"fr","max":"2100-01-01T00:00"},model:{value:(_vm.departureDate),callback:function ($$v) {_vm.departureDate=$$v},expression:"departureDate"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date et heure d'arrivée","label-for":"arrival-date-time"}},[_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"arrival-date-time","type":"datetime-local","locale":"fr","max":"2100-01-01T00:00"},model:{value:(_vm.arrivalDate),callback:function ($$v) {_vm.arrivalDate=$$v},expression:"arrivalDate"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('required-text-input-field',{attrs:{"name":_vm.t('group.transport_options.flight_segment.transport_number'),"label":_vm.t('group.transport_options.flight_segment.transport_number'),"label-for":"transport-number","placeholder":'TS123'},model:{value:(_vm.flightSegment.transportNumber),callback:function ($$v) {_vm.$set(_vm.flightSegment, "transportNumber", $$v)},expression:"flightSegment.transportNumber"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Compagnie aérienne","label-for":"tour-operator"}},[_c('validation-provider',{attrs:{"name":"Compagnie aérienne","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"tour-operator","options":_vm.airlines,"label":"name","reduce":function (airline){ return airline.name; }},on:{"search":_vm.searchAirline},model:{value:(_vm.flightSegment.operator),callback:function ($$v) {_vm.$set(_vm.flightSegment, "operator", $$v)},expression:"flightSegment.operator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }