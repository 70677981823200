<template>
  <b-card :title="basicInformation.name" header-class="d-flex" class="mt-2">
    <template #header>
      <h3>{{ t('group.modal.basic_info.modal_common_section') }}</h3>
      <div class="ml-auto">
        <b-button variant="outline-primary" class="ml-1" @click="showBasicInformationModal" v-if="canEdit">
          {{ t('button.edit') }}
        </b-button>
      </div>
      <group-basic-information-modal
        action="update"
        :information="basicInformation"
        :group-id="groupId"
        ref="group-basic-information-modal-ref"
        @monica-group-saved="fetchGroupBasicInformation"/>
    </template>
    <b-row>
      <b-col cols="3">
        <div>
          <h5 class="mb-75">{{ t('group.basic_info.type') }}</h5>
          <b-card-text>{{ getGroupType(basicInformation.type)  }}</b-card-text>
        </div>
        <div class="mt-3">
          <h5 class="mb-75">{{ t('group.basic_info.deposit') }}</h5>
          <b-card-text>{{ basicInformation.depositAmount || "-"}} $</b-card-text>
        </div>
        <div v-if="organizationPaymentEnabled" class="mt-3">
          <h5 class="mb-75">{{ t('group.basic_info.payment_enabled') }}</h5>
          <b-card-text>{{ basicInformation.paymentEnabled ? 'Oui' : 'Non' }}</b-card-text>
        </div>
      </b-col>
      <b-col cols="3">
        <div>
          <h5 class="mb-75">{{ t('group.basic_info.difficulty') }}</h5>
          <b-card-text>{{ getGroupDifficulty(basicInformation.difficulty) }}</b-card-text>
        </div>
        <div class="mt-3">
          <h5 class="mb-75">{{ t('group.basic_info.guide') }}</h5>
          <b-card-text>{{ basicInformation.guide || "-"}}</b-card-text>
        </div>
      </b-col>
      <b-col cols="3">
        <div>
          <h5 class="mb-75">{{ t('group.basic_info.departure_date') }}</h5>
          <b-card-text>{{ basicInformation.departureDate }}</b-card-text>
        </div>
        <div class="mt-3">
          <h5 class="mb-75">{{ t('group.basic_info.return_date') }}</h5>
          <b-card-text>{{ basicInformation.returnDate }}</b-card-text>
        </div>
      </b-col>
      <b-col cols="3">
        <div>
          <h5 class="mb-75">{{ t('group.basic_info.payment_limit') }}</h5>
          <b-card-text>{{ basicInformation.paymentLimit }}</b-card-text>
        </div>
        <div class="mt-3">
          <h5 class="mb-75">{{ t('group.basic_info.booking_limit') }}</h5>
          <b-card-text>{{ basicInformation.bookingLimit }}</b-card-text>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {BButton, BCard, BCardText, BCol, BRow, BTab, BTabs} from "bootstrap-vue";
import GroupBasicInformationModal
  from "@/modules/group/components/GroupBasicInformationModal";
import {useLocalisation} from "@/shared/composables/use-localisation";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {reactive, ref} from "@vue/composition-api/dist/vue-composition-api";
import {onMounted} from "@vue/composition-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import groupCreationConfig from '@core/data/groupCreationConfig.js'
import store from "@/store";
import {AUTH_STORE_NAMESPACE} from "@/modules/authnz/models/store";

export default {
  name: 'group-basic-information-card',
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupBasicInformationModal,
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const {refOf, forceUpdate} = useApplicationContext();
    const {displayErrorMessage} = useToastNotification();
    const {t} = useLocalisation();
    const {getGroupBasicInformation} = useGroupApi();

    let basicInformation = reactive({});
    let organizationPaymentEnabled = ref(false);

    try {
      organizationPaymentEnabled.value = store.state[AUTH_STORE_NAMESPACE].orgSettings.payment_enable;
    } catch (e) {
      console.error(e)
    }

    const showBasicInformationModal = () => {
      refOf('group-basic-information-modal-ref').$children[0].$children[0].show();
    };

    const hideBasicInformationModal = () => {
      refOf('group-basic-information-modal-ref').$children[0].$children[0].hide();
    };

    const fetchGroupBasicInformation = async (id) => {
      try {
        const retrievedGroupBasicInfo = await getGroupBasicInformation(id);
        Object.assign(basicInformation, retrievedGroupBasicInfo);
        forceUpdate();
        hideBasicInformationModal();
      } catch (e) {
        displayErrorMessage("Une erreur s'est produite en essayant de retrouver les information du groupe");
      }
    };

    onMounted(() => {
      fetchGroupBasicInformation(props.groupId);
    });

    const formatDateFromArray = (dateArray) => {
      if (dateArray && dateArray.length == 3) {
        //Add O if single Digit Month

        if (dateArray[1].toString().length == 1) {
          dateArray[1] = "0" + dateArray[1].toString();
        }
        //Add O if single Digit Day
        if (dateArray[2].toString().length == 1) {
          dateArray[2] = "0" + dateArray[2].toString();
        }

        return `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`
      } else {
        return null;
      }
    }

    const getGroupType = (typeId) => groupCreationConfig.type.find(x => x.value == typeId)?.text
    const getGroupDifficulty = (difficultyId) => groupCreationConfig.groupDifficulty.find(x => x.value == difficultyId)?.text

    return {
      basicInformation,
      organizationPaymentEnabled,
      formatDateFromArray,
      t,
      showBasicInformationModal,
      fetchGroupBasicInformation,
      getGroupType,
      getGroupDifficulty,
    };
  }
};
</script>
