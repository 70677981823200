<template>
  <b-card header-class="d-flex" class="mt-2">
    <template #header>
      <h3>{{ t('group.modal.advanced_info.modal_common_section') }}</h3>
      <b-button variant="outline-primary" class="ml-1" @click="showAdvancedInformationModal" v-if="canEdit">
        {{ t('button.edit') }}
      </b-button>
      <group-advanced-information-modal
        action="update"
        :information="advancedInformation"
        :group-id="groupId"
        ref="group-advanced-information-modal-ref"
        @monica-group-saved="fetchGroupAdvancedInformation"
      />
    </template>
    <b-row>
      <b-col cols="3" class="mb-1">
        <div>
          <h5 class=" mb-75">{{ t('group.advanced_info.link') }}</h5>
          <b-card-text v-if="advancedInformation.link" class="text-justify text-truncate"><a :href="`${advancedInformation.link}`" target="_blank" >{{ advancedInformation.link }}</a></b-card-text>
          <b-card-text v-else class="text-justify "><i>Non défini</i></b-card-text>

        </div>
      </b-col>
      <b-col cols="3">
        <div>
          <h5 class=" mb-75">{{ t('group.advanced_info.description') }}</h5>
          <p v-if="advancedInformation.description" style="white-space: pre-line" class="mb-75">{{ advancedInformation.description }}</p>
          <p v-else class="text-justify"><i>Non défini</i></p>
        </div>
      </b-col>
      <b-col cols="3">
        <div>
          <h5 class=" mb-75">{{ t('group.advanced_info.extra_information') }}</h5>
          <p v-if="advancedInformation.extraInformation" style="white-space: pre-line" class="mb-75">{{ advancedInformation.extraInformation }}</p>
          <p v-else class="text-justify"><i>Non défini</i></p>
        </div>
      </b-col>
      <b-col cols="3">
        <div>
          <h5 class=" mb-75">{{ t('group.advanced_info.requirements') }}</h5>
          <b-card-text v-if="advancedInformation.requirements" class=" mb-75">{{ advancedInformation.requirements }}</b-card-text>
          <b-card-text v-else class="text-justify"><i>Non défini</i></b-card-text>
        </div>
      </b-col>
      <b-col cols="3" class="mt-1">
        <div>
          <h5 class=" mb-75">{{ t('group.advanced_info.inclusions') }}</h5>
          <ul v-if="advancedInformation.inclusions && advancedInformation.inclusions.length > 0" style="list-style: none; padding-inline-start: 2px">
            <li v-for="inclusion in advancedInformation.inclusions" :key="inclusion">
              <span class="bullet bullet-sm mr-1 bullet-success"></span>
              <small>{{inclusion}}</small>
            </li>
          </ul>
          <b-card-text v-else class="text-justify"><i>Non défini</i></b-card-text>
        </div>
      </b-col>
      <b-col cols="3" class="mt-1">
        <div>
          <h5 class=" mb-75">{{ t('group.advanced_info.exclusions') }}</h5>
          <ul v-if="advancedInformation.exclusions && advancedInformation.exclusions.length > 0" style="list-style: none; padding-inline-start: 2px">
            <li v-for="exclusions in advancedInformation.exclusions" :key="exclusions">
              <span class="bullet bullet-sm mr-1 bullet-danger"></span>
              <small>{{exclusions}}</small>
            </li>
          </ul>
          <b-card-text v-else class="text-justify"><i>Non défini</i></b-card-text>
        </div>
      </b-col>
      <b-col cols="6" class="mt-1">
        <div>
          <h5 class=" mb-75">{{ t('group.advanced_info.images') }}</h5>
          <b-img v-if="advancedInformation.images" :src="advancedInformation.images" fluid ></b-img>
          <b-card-text v-else class="text-justify"><i>Non défini</i></b-card-text>

        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {BButton, BCard, BCardText, BCol, BRow, BTab, BTabs, BImg} from "bootstrap-vue";
import {useLocalisation} from "@/shared/composables/use-localisation";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {reactive} from "@vue/composition-api/dist/vue-composition-api";
import {onMounted} from "@vue/composition-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import GroupAdvancedInformationModal from "@/modules/group/components/GroupAdvancedInformationModal";

export default {
  name: 'group-advanced-information-card',
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BImg,
    BButton,
    GroupAdvancedInformationModal
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, {emit}) {
    const {refOf, forceUpdate} = useApplicationContext();
    const {displayErrorMessage} = useToastNotification();
    const {t} = useLocalisation();
    const {getGroupAdvancedInformation} = useGroupApi();

    let advancedInformation = reactive({});

    const showAdvancedInformationModal = () => {
      refOf('group-advanced-information-modal-ref').$children[0].show();
    };

    const fetchGroupAdvancedInformation = async () => {
      try {
        const retrievedGroupAdvancedInfo = await getGroupAdvancedInformation(props.groupId);
        Object.assign(advancedInformation, retrievedGroupAdvancedInfo);
        forceUpdate();
        emit('monica-group-saved');

      } catch (e) {
        displayErrorMessage("Une erreur est arrivée en essayant de retrouver les information du group");
      }
    };

    onMounted(() => {
      fetchGroupAdvancedInformation(props.groupId);
    });

    const formatTags = (tags) => {
      return tags !== undefined ? tags.join(', ') : tags;
    };

    return {
      advancedInformation,

      t,
      formatTags,
      showAdvancedInformationModal,
      fetchGroupAdvancedInformation,
    };
  }
};
</script>
