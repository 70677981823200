<template>
  <validation-provider #default="{ errors }" rules="required" :name="name">
    <b-form-group
      :label="label"
      :label-for="labelFor"
      :state="errors.length > 0 ? false : null"
    >
      <v-select
        v-model="innerValue"
        :options="availableOptions"
        :selectable="(option) => !option.value.includes('select_value')"
        :name="name"
        :label="valueLabel"
        :dir="dir"
        :ref="labelFor"
        :placeholder="placeholder"
        :reduce="(text) => text.value"
        :class="{ 'is-invalid': !!errors.length }"
        :multiple="multiple"
        :close-on-select="closeOnSelect"
        @input="(input) => $emit('input', input)"
      />
      <div v-if="errors[0]" class="invalid-feedback">{{ errors[0] }}</div>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "required-select-box",
  components: {
    BFormGroup,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    name: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: String | undefined,
      required: true,
    },
    dir: {
      type: String,
      required: false,
      default: "ltr",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    labelFor: {
      type: String,
      required: false,
      default: "",
    },
    availableOptions: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    valueLabel: {
      type: String,
      default: "text",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
