import axios from '@axios'

export class GroupCreationService {

  constructor() {}

  async createBasicGroup(currentGroup) {
    let body = {
      name: currentGroup.name,
      guide: currentGroup.guide,
      departure_date: currentGroup.departureDate,
      return_date: currentGroup.returnDate,
      type: currentGroup.type,
      difficulty: currentGroup.groupDifficulty,
      booking_limit: currentGroup.bookingLimit,
      payment_limit: currentGroup.paymentLimit,
      inclusions: [],
      exclusions: [],
      deposit_amount: null,
      description: null,
      extra_information: null,
      images: null,
      link: null,
      video: null,
      requirements: null

    }
    return new Promise((resolve, reject) => {
      axios
        .post(`/groups`, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

  async editBasicInfo(id, currentGroup) {

    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${id}`)
        .then(response => {

          let body = this.setBasicInfo(response.data, currentGroup)

          return axios.put(`/groups/${id}`, body)
        }).then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

  async editAdvancedInfo(id, currentGroup) {

    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${id}`)
        .then(response => {

          let body = this.setAdvancedInfo(response.data, currentGroup)

          return axios.put(`/groups/${id}`, body)
        }).then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

  formatDateFromArray(dateArray) {
    if (dateArray && dateArray.length == 3) {
      //Add O if single Digit Month

      if (dateArray[1].toString().length == 1) {
        dateArray[1] = "0" + dateArray[1].toString();
      }
      //Add O if single Digit Day
      if (dateArray[2].toString().length == 1) {
        dateArray[2] = "0" + dateArray[2].toString();
      }

      return `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`
    } else {
      return null;
    }
  }

  setBasicInfo(fullBody, currentGroup) {

    fullBody.name= currentGroup.name
    fullBody.guide= currentGroup.guide
    fullBody.departure_date= this.formatDateFromArray(currentGroup.departureDate)
    fullBody.return_date= this.formatDateFromArray(currentGroup.returnDate)
    fullBody.type = currentGroup.type
    fullBody.difficulty = currentGroup.groupDifficulty
    fullBody.booking_limit = this.formatDateFromArray(currentGroup.bookingLimit)
    fullBody.payment_limit= this.formatDateFromArray(currentGroup.paymentLimit)
    fullBody.deposit_limit= this.formatDateFromArray(currentGroup.deposit_limit)
    
    delete fullBody.id;

    return fullBody;
  }

  setAdvancedInfo(fullBody, currentGroup) {
    //I always need to format the date otherwise it will fail, thats a problem
    fullBody.departure_date= this.formatDateFromArray(fullBody.departure_date)
    fullBody.return_date= this.formatDateFromArray(fullBody.return_date)
    fullBody.booking_limit= this.formatDateFromArray(fullBody.booking_limit)
    fullBody.payment_limit= this.formatDateFromArray(fullBody.payment_limit)
    fullBody.deposit_limit= this.formatDateFromArray(fullBody.deposit_limit)
    
    fullBody.description = currentGroup.description
    fullBody.inclusions = currentGroup.inclusions
    fullBody.exclusions = currentGroup.exclusions
    fullBody.description = currentGroup.description
    fullBody.extra_information = currentGroup.extraInformation
    fullBody.link = currentGroup.link
    fullBody.requirements = currentGroup.requirements

    delete fullBody.id;

    return fullBody;
  }

}
