<template>
  <validation-observer ref="refFormObserver" #default="{ passes }" >
    <b-modal
      id="modal-select2"
      ref="flight-modal"
      title="Ajouter un segment"
      ok-title="Enregistrer"
      cancel-title="Annuler "
      cancel-variant="outline-secondary"
      size="lg"
      @ok.prevent="validateForm"
      no-close-on-backdrop
      @show="fillDataIfProvided"
      @cancel="resetForm"
    >
      <b-form>
        <b-row>
          <b-col
            v-if="action == 'update'"
            cols="12"
            class="mb-2"
          >

            <b-button variant="danger" @click=deleteSegment class="float-right" size="sm" > Supprimer </b-button>
          </b-col>
            <b-col md="6">
              <b-form-group
                label="Aéroport de départ"
                label-for="departure-airport"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Aéroport de départ"
                  rules="required"
                >
                  <v-select
                    v-model="flightSegment.departureStation"
                    :options="airports"
                    label="label"
                    :reduce="airport=>airport.iata"
                    @search="searchAirports"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    rules="required"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>


              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Aéroport d'arrivée"
                label-for="arrival-airport"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Aéroport de d'arrivée"
                  rules="required"
                >
                  <v-select
                    :options="airports"
                    label="label"
                    :reduce="airport=>airport.iata"
                    v-model="flightSegment.arrivalStation"
                    @search="searchAirports"
                    :class="{ 'is-invalid': errors.length > 0 }"


                  />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Date et heure de départ"
              label-for="departure-date-time"
            >
                <b-form-input
                  id="departure-date-time"
                  class="mb-1"
                  type="datetime-local"
                  v-model="departureDate"
                  locale="fr"
                  max="2100-01-01T00:00"
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date et heure d'arrivée"
              label-for="arrival-date-time"
            >
              <b-form-input
                  id="arrival-date-time"
                  class="mb-1"
                  type="datetime-local"
                  v-model="arrivalDate"
                  locale="fr"
                  max="2100-01-01T00:00"

                />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <required-text-input-field
              v-model="flightSegment.transportNumber"
              :name="t('group.transport_options.flight_segment.transport_number')"
              :label="t('group.transport_options.flight_segment.transport_number')"
              label-for="transport-number"
              :placeholder="'TS123'"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Compagnie aérienne"
              label-for="tour-operator"
            >

              <validation-provider
                #default="{ errors }"
                name="Compagnie aérienne"
                rules="required"
              >
                <v-select
                  id="tour-operator"
                  :options="airlines"
                  label="name"
                  :reduce="airline=>airline.name"
                  v-model="flightSegment.operator"
                  @search="searchAirline"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormTextarea,
  BTabs,
  BFormFile,
  BTab,
  BFormInvalidFeedback,

} from 'bootstrap-vue'
import dayjs from 'dayjs';
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import groupCreationConfig from '@core/data/groupCreationConfig.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {useTravelInformationApi} from "@/modules/group/composables/use-travel-information-api";
import {VueAutosuggest} from "vue-autosuggest";
import {computed, reactive, watch} from "@vue/composition-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import RequiredSelectBox from "@/shared/components/RequiredSelectBox";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";
import {useLocalisation} from "@/shared/composables/use-localisation";
import {useValidatorsWithAutoFormDataCleanUp} from "@/shared/composables/use-validators";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    RequiredSelectBox,
    RequiredTextInputField,
          ValidationProvider,
      ValidationObserver,
    },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: 'NOT_PROVIDED_BY_PARENT_COMPONENT',
    },
    action: {
      type: String,
      required: true,
    },
    flight: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      groupCreationConfig,
    }
  },
  setup(props, {emit}) {
    const {refOf, forceUpdate} = useApplicationContext();
    const {getAirlineInformationMatchingOperator, getAirportsMatchingIataCode} = useTravelInformationApi();
    const {t} = useLocalisation();

    const initialState = {
      arrivalDate: "",
      departureDate: "",
      departureStation: "",
      operator: "",
      arrivalStation: "",
      transportNumber: ""
    };

    const flightSegment = reactive({ ...initialState });
    const airlines = reactive([]);
    const airports = reactive([]);

    function resetFlightSegment() {
      Object.assign(flightSegment, initialState);
    }

    function setFlightSegment() {
      Object.assign(flightSegment, props.flight);
    }

    const fillDataIfProvided = () => {
      if (props.action === 'update') {
        setFlightSegment();
      } else {
        resetFlightSegment();
      }
    };

    const searchAirline = async (startingWith, loading) => {
      loading(true);
      const foundAirlines = await getAirlineInformationMatchingOperator(startingWith);
      airlines.splice(0);
      foundAirlines.forEach(e => airlines.push(e));
      loading(false);
    };

    const searchAirports = async (iataCode, toggleLoading) => {
      toggleLoading(true);
      const foundAirports = await getAirportsMatchingIataCode(iataCode);
      airports.splice(0);
      foundAirports.forEach(e => {
        e.label = `${e.iata} - ${e.name}`
        airports.push(e)
      });
      toggleLoading(false);
    };

    const submitNewFlightSegment = () => {
      if (props.action === 'update') {
        emit('editFlightSegment', flightSegment)
      } else {
        emit('createNewFlightSegment', flightSegment)
      }
    };

    const deleteSegment = () => {
      emit('deleteFlightSegment', flightSegment)
    }

    const {refFormObserver, resetForm} = useValidatorsWithAutoFormDataCleanUp(flightSegment);

    const validateForm = () => {
      refOf('refFormObserver').validate().then(success => {
        if (success) {
          submitNewFlightSegment();
          refOf('flight-modal').hide();
        }
      })
    }

    return {
      flightSegment,
      airlines,
      airports,

      searchAirline,
      searchAirports,
      submitNewFlightSegment,
      initialState,
      refFormObserver,
      resetForm,
      resetFlightSegment,
      setFlightSegment,
      fillDataIfProvided,
      t,
      validateForm,
      deleteSegment
    };
  },
  computed: {
    departureDate: {
      get() {
        const value = dayjs(this.flightSegment.departureDate, 'YYYY-MM-DD HH:mm');
        return value.format('YYYY-MM-DDTHH:mm');
      },
      set(value) {
        const newDate = dayjs(value, 'YYYY-MM-DDTHH:mm');
        this.flightSegment.departureDate = newDate.format('YYYY-MM-DD HH:mm');
      }
    },
    arrivalDate: {
      get() {
        const value = dayjs(this.flightSegment.arrivalDate, 'YYYY-MM-DD HH:mm');
        return value.format('YYYY-MM-DDTHH:mm');
      },
      set(value) {
        const newDate = dayjs(value, 'YYYY-MM-DDTHH:mm');
        this.flightSegment.arrivalDate = newDate.format('YYYY-MM-DD HH:mm');
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
