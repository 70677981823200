<template>
  <b-card
    border-variant="primary"
  >
    <b-row class="text-align-left">
      
      <b-col cols="3">
        <b-carousel
          img-width="200"
          img-height="200"
          controls
          indicators
        >
          <b-carousel-slide :img-src="image">
          </b-carousel-slide>
        </b-carousel>

      </b-col>
      
      <b-col cols="4">
        <div class="mb-1">
          <div>
            <span  class="room-category pr-1">{{roomInfo.room_category}}</span><a v-if="roomInfo.url" target="_blank" :href="sanitizeURL(roomInfo.url)"> Site Internet </a></div>
            <!-- <span>{{roomInfo.room_option}}</span> -->
        </div>
        <p v-if="roomInfo.description" class="text-justify">{{roomInfo.description}}</p>
        <p v-else ><i>Aucune description</i></p>
        <br>
        <b v-if="roomInfo.extra_information" >Extra Information</b>
        <p v-if="roomInfo.extra_information" class="text-justify">{{roomInfo.extra_information}}</p>

      </b-col>
      <b-col cols="2">
        <div>
          <h5>
            Inventaire initial
          </h5>
          <b-card-text>
            {{roomInfo.inventory}}
          </b-card-text>
        </div>
        <!--
        <div class="mt-1">
          <h5>
            Caractéristiques
          </h5>
          <b-card-text>

            <ul style="list-style: none; padding-inline-start: 2px">
              <li v-for="characteristic in roomInfo.characteristics" :key="characteristic.index">
                <span class="bullet bullet-sm mr-1 bullet-info"></span>
                <small>{{characteristic}}</small>
              </li>
            </ul>
          </b-card-text>
        </div>
        -->

      </b-col>
      <!--
      <b-col cols="2">
        <div >
          <h5>
            Inclusions
          </h5>
          <b-card-text>
            <ul style="list-style: none; padding-inline-start: 2px">
              <li v-for="inclusions in roomInfo.inclusions" :key="inclusions">
                <span class="bullet bullet-sm mr-1 bullet-success"></span>
                <small>{{inclusions}}</small>
              </li>
            </ul>
          </b-card-text>
        </div>
        <div class="mt-1">
          <h5>
            Exclusions
          </h5>
          <b-card-text>
            <ul style="list-style: none; padding-inline-start: 2px">
              <li v-for="exclusion in roomInfo.exclusions" :key="exclusion">
                <span class="bullet bullet-sm mr-1 bullet-danger"></span>
                <small>{{exclusion}}</small>
              </li>
            </ul>

          </b-card-text>
        </div>
      </b-col> 
      -->
      <b-col cols="1" class="text-align-right">
        <b-button v-if="canEdit"  variant="outline-primary" size="sm" @click="$emit('openEditRoomModal')">Modifier</b-button>
      </b-col>
    </b-row>
    


  </b-card>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel,BImg, BCarousel,BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    BImg,
    BCarousel,
    BCarouselSlide
  },
  props: {
    roomInfo: Object,
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasOneOption: true,
      testDate: Date.now(),
      image: require('@/assets/images/hotels/hotel_generic.png')
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    
    const sanitizeURL = (url) => {
      try {
       if (false === url.includes( 'https') ) {
         if (false === url.includes('http')) {
           url = "https://".concat(url)
         } else {
           url.replace('http', 'https')
         }
       } 

       return url;
      } catch {
        return ""
      }

    }
    return {
      sanitizeURL
    }
  },
}
</script>

<style>
  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-white {
    color:white;
  }

  .flight-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
  }

  .room-category {
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
  }

  .flight-time {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
  }

  .airport-city, .flight-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-top: 2px
  }

  .small-top-padding {
    padding: 0;
    padding-top:10px;
  }

</style>
